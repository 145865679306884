import { useState, useRef } from "react";
import Editor from "@monaco-editor/react";
import "./App.css";

function App() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [requestState, setRequestState] = useState("waiting");
  const editorRef = useRef(null);

  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }

  async function handleWebhookSubmit() {
    const editorData = editorRef.current.getValue();

    setRequestState("loading");

    await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: editorData,
    });

    setRequestState("done");
  }

  return (
    <div className="App">
      <h1>Webhook tester app</h1>
      <div>
        <label for="webhookUrl">Webhook URL</label> <br />
        <input
          type="text"
          id="webhookUrl"
          onChange={(e) => {
            setWebhookUrl(e.target.value);
          }}
        />
      </div>
      <div>
        <label for="webhookData">Webhook Data</label> <br />
        <Editor
          height="25vh"
          defaultLanguage="javascript"
          defaultValue="// place your JSON data here"
          onMount={handleEditorDidMount}
        />
      </div>
      <div>
        <button onClick={handleWebhookSubmit} class="Btn" type="button">
          Send webhook request
        </button>
      </div>
      <div>Status: {requestState}</div>
      <div class="footer">Made by Andrei with ❤️</div>
    </div>
  );
}

export default App;
